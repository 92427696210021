const titleCase = (text: string) => {
  if (text == null || typeof text !== 'string') {
    return text;
  }

  const result = text.replace(/_/g, ' ').replace(/([A-Z]{1,})/g, ' $1');
  return result
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export { titleCase };
