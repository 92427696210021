/* eslint-disable class-methods-use-this */
import { PropertyDeclarations } from 'lit';
import { NNBase, html, css } from '../../base/Base';
import '@vaadin/menu-bar';
import '@vaadin/notification';
import { ellipsisVertical } from '../../icons/ellipsisVertical';
import { titleCase } from '../../utils/titleCase';

interface Tile {
  image: string;
  id: string;
  name: string;
  footerTitle: string;
  footerSubtitle: string;
  options: Array<any>;
}

class NNTiles extends NNBase {
  static properties: PropertyDeclarations = {
    ...super.properties,
    data: { type: Array },
  };

  declare data: Array<Tile>;

  static styles: any = css`
    .container {
      --hue-1: 323;
      --hue-2: 265;
      font-family: 'Poppins', sans-serif;
      border: 1px solid transparent;
      background: linear-gradient(
            130deg,
            rgba(25, 28, 46, 0.96),
            rgba(17, 19, 34, 0.99),
            rgba(25, 28, 46, 0.96)
          )
          padding-box,
        linear-gradient(130deg, #1e293b, #263348, #1e293b) border-box;
      width: 350px;
      height: 230px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;
      transition: background 5s ease-in-out;
    }

    .wrapper {
      z-index: 2;
      display: flex;
      flex-direction: row;
      padding: 0.75rem;
      gap: 1rem;
    }

    .image-wrapper {
      border-radius: 50%;
      height: 80px;
      width: 80px;
      padding: 4px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }

    .brand-image {
      filter: brightness(0) invert(1);
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .footer {
      border-top: 1px solid rgba(255, 255, 255, 0.15);
      margin: 0.75rem;
      padding: 0.75rem 0;
      display: flex;
      justify-content: space-between;
      position: relative;
    }

    .blur__container {
      display: flex;
      width: 100%;
      justify-content: center;
      overflow: hidden;
    }

    .card__scale-1 {
      width: 80px;
      height: 36px;
      background: linear-gradient(
        130deg,
        hsl(323, 85%, 70%),
        #f8029c,
        #191c2e,
        #0dbeff,
        #6c00ff
      );
      border-radius: 2rem;
      top: 20px;
      left: 15px;
      margin: 0 auto;
      display: inline-block;
    }

    .card__scale-2 {
      width: 40px;
      height: 23px;
      background: #11132270;
      border-radius: 1.5rem;
      top: 20px;
      left: 15px;
      margin: 0 auto;
    }

    .card__scale-1,
    .card__scale-2 {
      position: absolute;
      filter: blur(20px);
      transition: transform 0.3s ease-in;
    }

    .container:hover .card__scale-1 {
      transform: scale(5) translateX(40px);
    }

    .container:hover .card__scale-2 {
      transform: scale(10);
      transition-delay: 0.1s;
    }

    .tiles__container {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
    }

    .project-type {
      font-size: 1.25rem;
      margin: 0.5rem 0 0 0.75rem;
      font-weight: 800;
      z-index: 99;
      background: linear-gradient(130deg, #f8029c, #c62ade);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: capitalize;
    }

    .project {
      margin: auto 12px;
    }

    .titles {
      display: flex;
      flex-wrap: wrap;
      font-size: 1rem;
      color: #cecece;
    }

    .report-button {
      visibility: hidden;
    }

    .container:hover .report-button {
      visibility: visible;
    }

    .copy-button {
      position: absolute;
      left: 0;
      top: 14px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    }

    .container:hover .copy-button {
      opacity: 1;
      visibility: visible;
    }

    .project-id {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
      font-size: 14px;
      color: #c9c9c9;
      width: 150px;
    }

    .container:hover .project-id {
      opacity: 0;
      visibility: hidden;
    }

    .container:hover {
      background: linear-gradient(
            130deg,
            rgba(25, 28, 46, 0.96),
            rgba(17, 19, 34, 0.99),
            rgba(25, 28, 46, 0.96)
          )
          padding-box,
        linear-gradient(
            130deg,
            hsl(323, 85%, 70%),
            #f8029c,
            #1e293b,
            #1e293b,
            #1e293b,
            #0dbeff,
            #6c00ff
          )
          border-box;
    }
  `;

  _buttonClicked(event, id: string) {
    event.preventDefault();

    if (event.ctrlKey || event.metaKey) {
      this.dispatchEvent(
        new CustomEvent('open-project-in-new-tab', {
          bubbles: true,
          composed: true,
          detail: {
            data: id,
          },
        })
      );
    } else {
      this.dispatchEvent(
        new CustomEvent('tile-clicked', {
          bubbles: true,
          composed: true,
          detail: {
            data: id,
          },
        })
      );
    }
  }

  _copyToClipboard(id: string) {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        // eslint-disable-next-line no-console
        console.log('Project ID copied to clipboard:', id);
        this._showNotification(`${id} successfully copied to clipboard`);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error('Failed to copy project ID:', err);
      });
    this.dispatchEvent(
      new CustomEvent('copy-id', { bubbles: true, composed: true })
    );
  }

  _showNotification(message: string) {
    const notification = document.createElement('vaadin-notification');
    notification.renderer = function renderNotification(root) {
      if (!root.firstChild) {
        const textNode = document.createTextNode(message);
        root.appendChild(textNode);
      }
    };
    notification.duration = 3000;
    notification.setAttribute('theme', 'success');
    document.body.appendChild(notification);
    notification.open();
  }

  _optionsClicked({ detail: { value } }, id) {
    if (value.text === 'Rename') {
      this._dispatchOptionClickedEvent('rename', id);
    }
    if (value.text === 'Delete') {
      this._dispatchOptionClickedEvent('delete', id);
    }
  }

  _dispatchOptionClickedEvent(option, id) {
    this.dispatchEvent(
      new CustomEvent('option-clicked', {
        bubbles: true,
        composed: true,
        detail: {
          option,
          data: id,
        },
      })
    );
  }

  render() {
    return html`
      <div class="tiles__container">
        ${this.data.map(data => {
          const image =
            data.image != null
              ? data.image
              : 'https://media.northify.net/icons/hate-audit-192x192.png';
          const options = (data.options || []).reduce((acc, curr) => {
            if (curr === 'rename' || curr === 'delete') {
              // eslint-disable-next-line no-param-reassign
              acc = [...acc, { text: titleCase(curr) }];
            }

            return acc;
          }, []);
          return html`
            <div class="container">
              <div class="blur__container">
                <div class="card__scale-1"></div>
                <div class="card__scale-2"></div>
              </div>
              <div class="wrapper">
                <div class="image">
                  <div class="image-wrapper">
                    <img
                      class=${data.image != null ? 'brand-image' : ''}
                      src=${image}
                      alt=${data.name}
                    />
                  </div>
                </div>
                <div class="content">
                  <div class="project">Living Journeys</div>
                  <!-- <div class="titles">
                    <div class="subTitle"></div>
                    <div class="title"></div>
                  </div> -->
                </div>
                <div class="actions">
                  <vaadin-menu-bar
                    .items="${[
                      {
                        component: this._createItem(),
                        children: options,
                      },
                    ]}"
                    @item-selected=${e => this._optionsClicked(e, data.id)}
                  ></vaadin-menu-bar>
                </div>
              </div>
              <h4 class="project-type">${data.name.replace(/[-_]/g, ' ')}</h4>
              <div class="footer">
                <div class="footer-left">
                  <nn-button
                    ghost
                    class="copy-button"
                    style="padding: 0.5rem .75rem; --nn-elements-default-border-radius: 8px; --nn-elements-button-font-size: 0.6rem;"
                    @click=${() => this._copyToClipboard(data.id)}
                    >COPY ID</nn-button
                  >
                  <div
                    class="project-id"
                    style="font-size: 14px; margin: 10px 0;"
                  >
                    ${data.id}
                  </div>
                </div>
                <div class="footer-right">
                  <nn-button
                    red
                    class="report-button"
                    style="padding: 0.5rem .75rem; --nn-elements-default-border-radius: 8px; --nn-elements-button-font-size: 0.6rem;"
                    @click=${e => this._buttonClicked(e, data.id)}
                    >GO TO REPORT</nn-button
                  >
                </div>
              </div>
            </div>
          `;
        })}
      </div>
    `;
  }

  // eslint-disable-next-line class-methods-use-this
  _createItem() {
    const svg: any = ellipsisVertical;
    const item = document.createElement('vaadin-menu-bar-item');
    const icon: any = document.createElement('nn-icon');

    icon.svg = svg;
    icon.style.cursor = 'pointer';

    item.appendChild(icon);

    return item;
  }
}

export { NNTiles };
